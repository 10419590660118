.bon-print {
    display: none;
}

@media print {
    .deznav, .search-section, .nav-header, .header, .form-part, .no-print  {
        width: 0 !important;
        height: 0 !important;
        margin: 0 !important;
        padding: 0 !important;
        display: none !important;
    }

    .bon-print {
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        display: block !important;
        width: 100%;
        height: 100%;
    }

}