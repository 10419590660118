.factures > div {
    border-bottom: 1px solid lightgrey;
}

label {
    font-size: 18px;
    color: #24439D;
}

.checkbox-danger .custom-control-label::before {
    border-color: #24439D !important;
}

.btn-primary-outline {
    color: #24439D !important;
    text-decoration: underline;
}

.btn-danger-outline {
    color: #EE3232 !important;
    text-decoration: underline;
}