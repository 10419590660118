.health-card .card-header {
    justify-content: flex-end !important;
    font-size: 25px !important;
    font-weight: bold !important;
    background-color: #24439D !important;
    color: white !important;
}

.health-card .card-body > div {
    background-color: #f1f3f4 !important;
}

.health-card .card-body > div p {
    margin: unset !important;
    font-size: 15px;
}

.health-card .card-body > div p span{
    font-size: 13px;
    text-decoration: underline;
    font-weight: bold;
}

.health-card .card-body {
    padding-top: 0 !important;
}