.p-fileupload .p-fileupload-content {
    background: #ffffff;
    padding: unset !important;
    border: 1px solid #dee2e6;
    color: #495057;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

.p-fileupload .p-fileupload-buttonbar {
    background: #f8f9fa;
    padding: 0.25rem 1.25rem !important;
    border: 1px solid #dee2e6;
    color: #343a40;
    border-bottom: 0 none;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}